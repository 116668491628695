#contact {
    padding: 50px 10px 50px 10px;
    background: var(--footerBackground);
}

.infoItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.infoText {
    font-weight: bold;
    font-size: 24px;
    color: var(--contactInfoText);
}

#infoIcon {
    margin: 5px 20px 0 20px;
    font-size: 30px;
    color: var(--contactInfoIcon);
}

#infoIconInstagram {
    margin: 5px 20px 0 20px;
    font-size: 60px;
    color: var(--contactInfoIcon);
    transition: all 0.3s;
}

#infoIconInstagram:hover {
    font-size: 70px;
    color: var(--contactInfoIconActive);
}

#infoIconFacebook {
    margin: 5px 20px 0 20px;
    font-size: 55px;
    color: var(--contactInfoIcon);
    transition: all 0.3s;
}

#infoIconFacebook:hover {
    font-size: 65px;
    color: var(--contactInfoIconActive);
}

.infoTextSmartphone {
    font-weight: 600;
    font-size: 18px;
    color: var(--contactInfoText);
}

#infoIconInstagramSmartphone {
    margin: 5px 20px 0 20px;
    font-size: 50px;
    color: var(--contactInfoIcon);
    transition: all 0.3s;
}

#infoIconFacebookSmartphone {
    margin: 5px 20px 0 20px;
    font-size: 45px;
    color: var(--contactInfoIcon);
    transition: all 0.3s;
}