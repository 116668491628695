nav {
    overflow: hidden;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background: var(--navBackground);
}

#nav {
    height: 100px;
    transition: all 0.3s;
}

#navLeft {
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100px;
    margin-left: 50px;
    font-weight: bold;
    font-size: 26px;
    color: var(--navLeftText);
    transition: all 0.3s;
}

#navLeft:hover {
    font-size: 28px;
    color: var(--navLeftTextActive);
}

#navCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.navLink a {
    cursor: pointer;
    margin: 10px 10px 10px 30px;
    padding: 3px 20px 3px 20px;
    border-radius: 20px;
    background: var(--navLinkBackground);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    color: var(--navLinkText);
    transition: all 0.3s;
}

.navLink a:hover {
    padding: 3px 30px 3px 30px;
    background: var(--navLinkBackgroundActive);
}

.navLinkIcon {
    margin: 4px 10px 0 0;
}

#navRight {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

#navLinkedinIcon {
    margin: 0 10px 0 10px;
    font-size: 35px;
    color: var(--navSocialIcon);
    transition: all 0.3s;
}

#navLinkedinIcon:hover {
    margin: 0 10px 0 10px;
    font-size: 40px;
    color: var(--navSocialIconActive);
}

#navFacebookIcon {
    margin: 0 10px 0 10px;
    font-size: 30px;
    color: var(--navSocialIcon);
    transition: all 0.3s;
}

#navFacebookIcon:hover {
    margin: 0 10px 0 10px;
    font-size: 35px;
    color: var(--navSocialIconActive);
}

#navCenterSmartphone {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: var(--navLeftText);
    font-weight: bold;
    font-size: 30px;
    transition: all 0.3s;
}

#navSmartphone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
}

#navSmartphoneCenter {
    width: 80%;
    margin: 40px 0 0 10%;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    color: var(--navLeftText);
}

#navSmartphoneRight {
    width: 20%;
    margin-top: 40px;
    color: var(--navLeftText);
    font-size: 30px;
}

#navSmartphoneCloseIcon {
    font-size: 40px;
    color: var(--navSmartphoneCloseIcon);
}

#navSmartphoneLinks {
    padding: 10px 5px 20px 5px;
    background: var(--navBackground);
}

.navSmartphoneLink {
    cursor: pointer;
    padding: 10px 0 10px 0;
    transition: all 0.3s;
}

.navSmartphoneLink a {
    text-decoration: none;
    font-size: 18px;
    color: var(--navSmartphoneLink);
}

#navSmartphoneLinkedinIcon {
    margin: 20px 50px 0 50px;
    font-size: 30px;
    color: var(--navSocialIcon);
}

#navSmartphoneFacebookIcon {
    margin: 20px 50px 0 50px;
    font-size: 25px;
    color: var(--navSocialIcon);
}