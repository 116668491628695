:root {

    --navBackground: #000000;
    --navLeftText: #e9d2c4;
    --navLeftTextActive: #eee5e0;
    --navLinkBackground: #e9d2c4;
    --navLinkText: #662f0f;
    --navLinkBackgroundActive: #eee5e0;
    --navSocialIcon: #e9d2c4;
    --navSocialIconActive: #eee5e0;
    --navSmartphoneLink: #e9d2c5;
    --navSmartphoneCloseIcon: #ebc6c6;

    --sectionLightBackground: #eee6e2;
    --sectionLightTitle: #000000;
    --sectionDarkBackground: #854825;
    --sectionDarkTitle: #eee6e2;

    --carouselBackground: #000000;

    --contactInfoText: #e9d2c4;
    --contactInfoIcon: #e9d2c4;
    --contactInfoIconActive: #eee5e0;

    --footerBackground: #000000;

}