.App {
    text-align: center;
}

.sectionLight {
    padding: 100px 10px 0px 10px;
    background: var(--sectionLightBackground);
}

.sectionLightTitle {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 22px;
    color: var(--sectionLightTitle);
}

.sectionDark {
    padding: 100px 10px 100px 10px;
    background: var(--sectionDarkBackground);
}

.sectionDarkTitle {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 36px;
    color: var(--sectionDarkTitle);
}