#realization {
    padding-top: 50px;
}

.carousel {
    width: 90%;
    margin: 0 10px 10px 5%;
    overflow: hidden !important;
    border-radius: 20px !important;
    box-shadow: 0 0 10px var(--carouselShadow) !important;
    background: var(--carouselBackground);
}
